import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import wallet from "./wallet.png"
import Toast from "./toast"
import usdt from "./usdt.png"
import btc from "./btc.png"
import man from "./man.png"
import woman from "./founder.jpg"
import vid6 from "./vid6.mp4"



export default function Dashboard({ isLogged, setIsLogged }) {
    const history = useHistory()
    useEffect(() => {
        !isLogged && history.push("/login")
        !isLogged && Toast("You need to gain access to access dashbaord")
    }, [])

    
    const myData = JSON.parse(sessionStorage.getItem("clearvalueData"))


    return (
        <Flex w="100%">
            {
                myData?.paid === "no" ?
                <Flex w="100%" justify="center" py="60px" minH="60vh" bg="rgba(64, 1, 12, .1)">
                    <Flex bg="#fff" boxShadow="rgba(0, 0, 0, 0.05) 0px 1px 10px 4px" py={["8", "12"]} w={["92%", "670px"]} px={["5", "12"]} direction="column" borderRadius="8px" data-aos="fade-up">
                        <Text fontWeight="600" fontSize={["25px", "28px"]}>Account Access Pending!</Text>
                        <Text mt="5" textTransform="capitalize" color="slategray" fontWeight="600" fontSize="20px">Welcome, {myData?.fname}</Text>
                        <Text color="slategray" fontSize="14px" mt="2">Your account access is still pending either due to pending entry fee payment verification or you haven't paid the entry fee at all.</Text>

                        <Flex mt="6" w="100%" borderRadius="12px" border="1px solid #044eb0" padding="10px"><Flex w="100%" justify="center" cursor="pointer" borderRadius="30px" padding={["10px 15px", "10px 28px"]} bg="#044eb0" color="#fff"  _hover={{ bg: "rgba(6, 95, 242, .2)", color: "#000" }} transition="300ms ease-in-out" fontWeight="500" align="center" fontSize={["13px", "15px"]} onClick={() => {
                            Toast("Connecting you to the admin..", "success")
                            setTimeout(function() {
                                window.tidioChatApi.open()
                            }, 1500)
                        }} textAlign="center"><Text mr="2"><i className="mdi mdi-arrow-right"></i></Text>Contact admin and verify your payment</Flex></Flex>
                        
                        <Text color="slategray" fontSize="20px" my="6" textAlign="center">Or</Text>

                        <Text fontSize="17px" fontWeight="600" color="slategray">Haven't Paid $500 Entry Fee</Text>
                        <Text color="slategray" fontSize="14px" mb="5" mt="2">Select one of the crypto payment options either BTC or USDT (ERC 20). Contact our live chat after payment to verify your payment.</Text>

                        <Flex borderRadius="8px" border="1px solid rgb(200,200,200)" px="2" py="2" align="center" cursor="pointer" onClick={() => {
                            navigator.clipboard.writeText("bc1qcehxgg6lr3u5wp2msudm4a8v9vcwrcue8pwp9k")
                            Toast("BTC wallet copied to your clipboard", "success")
                        }}>
                            <Flex flex="1" align="center">
                                <Image src={btc} w="30px" h="30px" />
                                <Text ml="3" textDecoration="underline" wordBreak="break-all" fontSize={["12px", "15px"]}>bc1qcehxgg6lr3u5wp2msudm4a8v9vcwrcue8pwp9k</Text>
                            </Flex>
                            <Flex ml="2" w="35px" h="35px" fontSize="15px" cursor="pointer" border="1px solid #044eb0" justify="center" align="center" color="#044eb0" borderRadius="8px"><i className="mdi mdi-content-copy"></i></Flex>
                        </Flex>

                        <Flex mt="3" borderRadius="8px" border="1px solid rgb(200,200,200)" px="2" py="2" align="center" cursor="pointer" onClick={() => {
                            navigator.clipboard.writeText("0xB4eD03309446C0D8dEdA2482e0ED09EC3D19f0A7")
                            Toast("USDT wallet copied to your clipboard", "success")
                        }}>
                            <Flex flex="1" align="center">
                                <Image src={usdt} w="30px" h="30px" />
                                <Text ml="3" textDecoration="underline" wordBreak="break-all" fontSize={["12px", "15px"]}>0xB4eD03309446C0D8dEdA2482e0ED09EC3D19f0A7 (ERC 20)</Text>
                            </Flex>
                            <Flex ml="2" w="35px" h="35px" fontSize="15px" cursor="pointer" border="1px solid #044eb0" justify="center" align="center" color="#044eb0" borderRadius="8px"><i className="mdi mdi-content-copy"></i></Flex>
                        </Flex>

                    </Flex>
                </Flex>
                :
                <Flex w="100%" justify="center" py={["40px", "60px"]} minH="60vh" bg="linear-gradient(to right, #000914, #092a54)" direction="column" align="center">
                    <Flex w={["92%", "80%"]} justify="space-between" boxShadow="0rem 0rem 10rem 0rem rgba(72,240,179,0.188)" data-aos="fade-up" direction={["column", "row"]}>
                        <Flex w={["100%", "42%"]} bg="#fff" direction="column" py="8" px="6" justify="center" align="center" borderRadius={["8px 8px 0 0", "8px 0 0 8px"]} mb={["4", "0"]}>
                            <Flex justify="center"><Image w="150px" h="150px" src={man} /></Flex>

                            <Flex w="100%" justify="center" align="center" mt="6"><Text fontSize="20px"><i className="mdi mdi-account-circle"></i></Text> <Text ml="2" color="slategray" textTransform="capitalize">{myData?.fname}</Text></Flex>

                            <Flex w="100%" justify="center" align="center" mt="4" borderTop="1px solid rgb(200,200,200)" pt="3"><Text fontSize="20px"><i className="mdi mdi-email"></i></Text> <Text ml="2" color="slategray">{myData?.email}</Text></Flex>

                            <Flex w="100%" justify="center" align="center" mt="4" borderTop="1px solid rgb(200,200,200)" pt="3"><Text fontSize="20px"><i className="mdi mdi-phone-outgoing"></i></Text> <Text ml="2" color="slategray">{myData?.phone}</Text></Flex>
                        </Flex>




                        <Flex w={["100%", "56.6%"]} bg="#fff" borderRadius={["0 0 8px 8px", "0 8px 8px 0"]} direction="column" px={["4", "8"]} py="8">
                            <Flex w="100%">
                                <Image src={woman} w="50px" h="50px" borderRadius="100%" />
                                <Text ml="3">
                                    <Text fontWeight="600" fontSize="18px">Membership</Text>
                                    <Text mt="1" fontSize="12px" color="slategray">Membership clubs offer various benefits such as discounts, access to exclusive events, specialized services, networking opportunities, and unique member-only perks, depending on your level of investment</Text>
                                </Text>
                            </Flex>

                            <Text mt="8" fontWeight="500" fontSize="14px" mb="2">Membership Level</Text>
                            <Flex w="100%" border="2px solid #02c902" borderRadius="8px">
                                <Flex w={myData?.level + "%"} bg="#02c902" justify="center" fontSize="10px" fontWeight="600" color="#fff" align="center" py="1px">{myData?.level + "%"}</Flex>
                            </Flex>

                            <Flex w="100%" mt="6" borderTop="1px solid rgb(200,200,200)" pt="4" direction="column">
                                <Text fontSize="14px" mb="2">Total Payment</Text>
                                <Flex align="center">
                                    <Image src={wallet} w="40px" h="40px" />
                                    <Text fontSize="20px" ml="4">Entry Fee: <Text as="span" fontWeight="600" ml="2">$500</Text></Text>
                                </Flex>
                            </Flex>

                            <Flex w="100%" mt="6" borderTop="1px solid rgb(200,200,200)" pt="4" direction="column">
                                <Text fontSize="20px" mb="2">Quick Actions</Text>
                                <Flex w="100%" align="center" mt="5" justify="space-between">
                                    <Flex w="48%" justify="center" cursor="pointer" borderRadius="30px" padding={["12px 15px", "10px 28px"]} fontSize={["12px", "15px"]} bg="rgba(6, 95, 242, .21)" color="#000" _hover={{ bg: "#001e42", color: "#fff" }} transition="300ms ease-in-out" fontWeight="500" align="center" onClick={() => {
                                        Toast("Connecting you to the admin..", "success")
                                        setTimeout(function() {
                                            window.tidioChatApi.open()
                                        }, 1500)
                                    }}><Text mr="2"><i className="mdi mdi-chart-bar"></i></Text>Invest Now</Flex>

                                    <Flex w="48%" justify="center" cursor="pointer" borderRadius="30px" padding={["12px 15px", "10px 28px"]} fontSize={["12px", "15px"]} bg="#001e42" color="#fff" _hover={{ bg: "rgba(6, 95, 242, .2)", color: "#000" }} transition="300ms ease-in-out" fontWeight="500" align="center" onClick={() => {
                                        Toast("Connecting you to the admin..", "success")
                                        setTimeout(function() {
                                            window.tidioChatApi.open()
                                        }, 1500)
                                    }}><Text mr="2"><i className="mdi mdi-currency-btc"></i></Text>Mentorship</Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex w={["92%", "80%"]} data-aos="fade-up" justify="space-between" mt="5" boxShadow="0rem 0rem 10rem 0rem rgba(72,240,179,0.188)" bg="#fff" py={["8", "12"]} px={["4", "12" ]}borderRadius="8px" direction="column"> 
                        <Flex w="100%" justify="center">
                            <video controls playsInline style={{ background: "#000", width: "100%", borderRadius: "8px"}} src={vid6}></video>
                        </Flex>
                        <Flex w="100%" mt="4" justify="center" cursor="pointer" borderRadius="30px" padding="10px 28px" bg="#001e42" color="#fff" _hover={{ bg: "rgba(6, 95, 242, .2)", color: "#000" }} transition="300ms ease-in-out" fontWeight="500" align="center" fontSize={["13px", "15px"]} onClick={() => {
                            Toast("Connecting you to Brian Kim..", "success")
                            setTimeout(function() {
                                window.tidioChatApi.open()
                            }, 1500)
                        }}><Text mr="4"><i className="mdi mdi-message-outline"></i></Text>Contact Brian Kim</Flex>
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}