import { useState, useEffect } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "./logo.png"
import Toast from "./toast";




export default function Nav({ isLogged, setIsLogged }) {
    const history = useHistory()
    const [bgC, setBgC] = useState("none")
    const [menu, setMenu] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleNavScroll = () => {
        const position = window.pageYOffset;
        if (position > 15) {
            setBgC("0 3px 5px -1px rgba(20, 20, 20, .05)")
        } else {
            setBgC("none")
        }
	}

	useEffect(() => {
        window.addEventListener('scroll', handleNavScroll);
        return () => {
            window.removeEventListener('scroll', handleNavScroll)
        }
	})

    return (
    <Flex position="sticky" top="0" left="0" w="100%" px={["5", "8%"]} backdropFilter="blur(8px)" bg="rgba(255,255,255,0.85)" py="3" align="center" zIndex="100" boxShadow={bgC}>
        <Flex align="center">
            <Flex align="center" cursor="pointer" onClick={() => history.push("/")}>
                <Image src={Logo} borderRadius="5px" h={["30px", "45px"]} mr={["2", "2.5"]} />
                <Text display={["none", "block"]}>
                    <Text fontWeight="bold" fontSize={["8px", "15px"]}>ClearValue Tax</Text>
                    <Text fontSize={["7px", "12px"]} color="slategray">...obtain financial freedom</Text>
                </Text>
            </Flex>
        </Flex>

        <Flex flex="1" align="center" justify="flex-end">
            <Flex cursor="pointer" mr={["2", "4"]} w={["35px", "40px"]} h={["35px", "40px"]} borderRadius="100%" border="1px solid #044eb0" color="#044eb0" justify="center" align="center" fontSize="20px" _hover={{ color: "#000", border: "1px solid #000" }} transition="300ms ease-in-out" onClick={() => history.push(isLogged ? "/dashboard" : "/login")}><i className="mdi mdi-account-circle"></i></Flex>

            <Flex cursor="pointer" mr={["2", "4"]} w={["35px", "40px"]} h={["35px", "40px"]} borderRadius="100%" border="1px solid #044eb0" color="#044eb0" justify="center" align="center" fontSize="20px"  _hover={{ color: "#000", border: "1px solid #000" }} transition="300ms ease-in-out" onClick={() => {
                Toast("Connecting you to Brian Kim..", "success")
                setTimeout(function() {
                    window.tidioChatApi.open()
                }, 1500)
            }}><i className="mdi mdi-headset"></i></Flex>

            <Flex cursor="pointer" borderRadius="30px" padding={["8px 15px", "10px 28px"]} bg="#044eb0" color="#fff" _hover={{ bg: "rgba(6, 95, 242, .2)", color: "#000" }} transition="300ms ease-in-out" fontWeight="500" fontSize={["11px", "14px"]} onClick={() => history.push(isLogged ? "/dashboard" : "/register")}>{isLogged ? "Dashboard" : "Join Us"}</Flex>

            {isLogged && <Flex ml={["2", "4"]} cursor="pointer" borderRadius="30px" padding={["8px 15px", "10px 28px"]} bg="#044eb0" color="#fff" _hover={{ bg: "rgba(6, 95, 242, .2)", color: "#000" }} transition="300ms ease-in-out" fontWeight="500" fontSize={["11px", "14px"]} onClick={() => {
                setLoading(true)
                setTimeout(function() {
                    sessionStorage.removeItem("clearvalueisLogged")
                    sessionStorage.removeItem("clearvalueData")
                    setIsLogged(false)
                    setLoading(false)
                    history.push("/login")
                }, 1500)
            }}>{loading ? <Spinner color="#000" emptyColor="lightgrey" /> : "Log Out"}</Flex>}
        </Flex>
    </Flex>
    )
}