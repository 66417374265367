import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
  	ChakraProvider,
} from "@chakra-ui/react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Landing from './landing';
import Login from './login';
import Register from './register';
import ScrollToTop from "./sc";
import Nav from './nav';
import Footer from "./footer"
import Dashboard from './dashboard';

function App() {
	const [isLogged, setIsLogged] = useState(sessionStorage.getItem("clearvalueisLogged") !== null ? true : false)
	useEffect(() => {
		Aos.init({ duration: 800 })
	}, [])

	return (
		<ChakraProvider>
			<Flex w="100%" fontSize="15px" bg="#fff">
				<Router>				
					<ScrollToTop>
						<Flex w="100%" direction="column" color="#000">
							<Nav isLogged={isLogged} setIsLogged={setIsLogged} />
							<Switch>
								<Route exact path="/"><Landing /></Route>
								<Route path="/register"><Register isLogged={isLogged} /></Route>
								<Route path="/login"><Login isLogged={isLogged} setIsLogged={setIsLogged} /></Route>
								<Route path="/dashboard"><Dashboard isLogged={isLogged} setIsLogged={setIsLogged} /></Route>
								<Route><Landing /></Route>
							</Switch>
							<Footer />
						</Flex>
					</ScrollToTop>
				</Router>
			</Flex>
		</ChakraProvider>
	)
}

export default App;
