import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import woman from "./woman.png"
import Toast from "./toast"
import { ApiRequest } from "./request";



export default function Login({ isLogged, setIsLogged }) {
    const history = useHistory()
    useEffect(() => {
        isLogged && history.push("/dashboard")
    }, [])
    const ref1 = useRef("")
    const ref2 = useRef("")
    const [loading, setLoading] = useState(false)

    async function gainAccess() {
        const a = ref1.current.value
        const b = ref2.current.value

        if(a !== "" && b !== "") {
            setLoading(true)
            const res = await ApiRequest("post", "login.php", {
                email: a,
                pass: b,
            })
            if(res?.status === true) {
                Toast(<Text textTransform="capitalize">{res.message}</Text>, "success")
                sessionStorage.setItem("clearvalueisLogged", true)
                sessionStorage.setItem("clearvalueData", JSON.stringify(res.data))
                setIsLogged(true)
                setTimeout(function() {
                    history.push("/dashboard")
                }, 1500)
            }
            else {
                Toast(res?.message, "error")
            }
            setLoading(false)
        }
        else {
            Toast("Kindly fill all the input fields", "error")
        }
    }

    return (
        <Flex w="100%" justify="center" py="60px" minH="60vh" bg="linear-gradient(to right, #000914, #092a54)">
            <Flex bg="#fff" boxShadow="0rem 0rem 10rem 0rem rgba(72,240,179,0.188)" py={["10", "12"]} w={["90%", "630px"]} px={["4", "8"]} direction="column" data-aos="fade-up">
                <Flex mb="6">
                    <Image src={woman} w="60px" h="60px" />
                    <Text ml="3">
                        <Text fontSize={["26px", "32px"]} fontWeight="700">Sign In</Text>
                        <Text color="slategray" fontSize={["13px", "14px"]}>Kindly fill in your account credentials</Text>
                    </Text>
                </Flex>
                
                <Flex w='100%'><input type="email" style={{ width: "100%", border: "1px solid #044eb0", borderRadius: "30px", padding: "12px 20px"}} placeholder="Enter your account email" ref={ref1} /></Flex>

                <Flex mt="3" w='100%'><input type="password" style={{ width: "100%", border: "1px solid #044eb0", borderRadius: "30px", padding: "12px 20px"}} placeholder="Enter your account password" ref={ref2} /></Flex>

                <Flex mt="5" w="100%" justify="center" cursor="pointer" borderRadius="30px" padding="12px 28px" bg="#044eb0" color="#fff" _hover={{ bg: "rgba(6, 95, 242, .2)", color: "#000" }} transition="300ms ease-in-out" fontWeight="500" align="center" onClick={() => gainAccess()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey"/> : <><Text mr="2"><i className="mdi mdi-account"></i></Text>Sign In</>}</Flex>

                <Text mt="8" as="span" textAlign="center" fontSize={["14px", "15px"]}>Don't have an account? <Text ml="2" fontWeight="600" color="#044eb0" cursor="pointer" _hover={{ textDecoration: "underline" }} as="span" onClick={() => history.push("/register")}>Register Here</Text></Text>


            </Flex>
        </Flex>
    )
}